h1,
p {
  font-family: Lato;
}

body {
  width: 100vw;
  height: 100vh;
  background-color: #f1f1da;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: "Quicksand", sans-serif;
}

#root,
.Piano {
  height: 100%;
  min-width: 100%;
}

.Piano {
  display: flex;
  flex: 1 1;
  /*flex-direction: column;*/
}

.MainMenuDot {
  /*Bounce In Effect from Hover.css https://ianlunn.github.io/Hover/*/
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  /*mine */
  position: absolute;
  top: 5%;
  right: 5%;
  height: 50px;
  width: 50px;
  background-color: #129614;
  border-radius: 50%;
  z-index: 10;
  text-align: center;
}

.MainMenuDot span {
  font-size: 34px;
  color: yellow;
}

/* taken from https://ianlunn.github.io/Hover/ */

.MainMenuDot:hover,
.MainMenuDot:focus,
.MainMenuDot:active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

.MainMenu {
  display: none;
  position: absolute;
  justify-content: space-between;
  flex-direction: column;
  width: 90%;
  left: 5%;
  top: 10%;
  z-index: 11;
  padding: 20px;
  border-radius: 20px;
}

.MainMenu .div {
  flex: 1 1;
  text-align: center;
  margin-top: 10px;
}

.Menu-Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.MainMenu .closeMenu {
  width: 40px;
  align-self: flex-end;
}

.MainMenu.open {
  display: flex;
  background-color: beige;
}

.modalCover {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
}

/* taken from http://animista.net/ */

.slide-in-top {
  -webkit-animation: slide-in-top 0.8s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: slide-in-top 0.8s cubic-bezier(0.23, 1, 0.32, 1) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-6-28 17:12:54
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.modalCover.open {
  display: block;
}

/* MainMenu - circle of fifths */

.circleFifths .note:hover path {
  fill-opacity: 100;
  stroke-width: 0;
}

.circleFifths .note.active path {
  fill-opacity: 100;
  stroke-width: 0;
}

/* keyboard */

.Keyboard {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: center; /* align items in Main Axis */
  align-items: stretch;
}

/** Key **/

.Key {
  flex: 1 1;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  pointer-events: none; /* makes it click thru */
}

.Key .note {
  opacity: 0.4;
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  display: flex;
  flex-direction: column;
}

.Key.on.active .color-key {
  box-shadow: inset 0px 0px 3px black;
}
/*
.Key.on.active .piano-key.white {
  box-shadow: inset 0px 0px 5px black;
}

.Key.on.active .piano-key.black .blackPianoKey {
  box-shadow: inset 0px 0px 8px white;
}*/

.c-mark {
  border-left: 3px solid #000000;
}

.Key .seventh {
  position: absolute;
  top: 15%;
  font-size: 24px;
  text-align: center;
}

/*.Key .note:first-child {
  height: 120px;
  background-color: white;
  opacity: 1;
}*/

.note .off {
  /*do not display the note names */
  display: none;
}

.Piano .Key.off {
  display: none;
}

.Piano.showOffNotes .Key.off {
  display: flex;
}

.Key .piano-key {
  border-top: 0px;
  z-index: 2;

  border-bottom: 1px solid #3f3f3f;
}

.Key.black {
  z-index: 3
}

.Key.black .piano-key {
  height: 30%;
  background-color: transparent;
}

.Key.black .blackPianoKeyContainer {
  height: 100%;
}
.Key.black .piano-key .blackPianoKeyFiller {
  height: 35%;
}
.Key.black .piano-key .blackPianoKey {
  pointer-events: all;
  height: 65%;
  background-color: black;
}

.Key.white .piano-key {
  height: 30%;
  background-color: white;
  border-left: 1px solid #3f3f3f;
  width: 171%;
  position: relative;
  pointer-events: all;
}

.Key.on .piano-key.white:hover, .Key.on .piano-key.black .blackPianoKey:hover {
  background-color: #aaa;
}

.Key.off .piano-key.white {
  background-color: #ddd;
}
.Key.off .piano-key.black .blackPianoKey {
  background-color: #666;
}

.Key.on .color-key {
  pointer-events: all;
}

.piano-key.c { left: 0%; }
.piano-key.d { left: -34%; }
.piano-key.e { left: -65%; }
.piano-key.f { left: 6%; }
.piano-key.g { left: -22%; }
.piano-key.a { left: -50%; }
.piano-key.b { left: -76%; }

.piano-key.e, .piano-key.b { border-right: 1px solid #3f3f3f; }


.black {
  /*background-color: black;*/
  border-top: 0px;
  z-index: 2;
}


.text {
  text-align: center;
}

.octave button,
.octave p {
  display: inline-block;
}
.octave button {
  margin: 3px 3px;
  width: 40px;
}

/** Musical Staff Overlay */

.musical-staff {
  position: absolute;
  top: 30%;
  z-index: 10;
  pointer-events: none; /* makes it click thru */
}

.musical-staff svg {
  width: 100%;
  height: auto;
}

.musical-staff svg > rect {
  width: 0 !important;
}

/*** toggle switch */
.checkbox {
  position: relative;
  display: inline-block;
}
.checkbox:after,
.checkbox:before {
  font-family: FontAwesome;
  font-feature-settings: normal, auto, normal;
  -webkit-font-kerning: auto;
  font-kerning: auto;
  font-language-override: normal;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-weight: normal;
  text-rendering: auto;
}
.checkbox label {
  width: 90px;
  height: 42px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.checkbox label:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  left: 0;
  top: -5px;
  z-index: 2;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}
.checkbox input:hover + label:after {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
}
.checkbox input:checked + label:after {
  left: 40px;
}

.toggle-switch .checkbox label {
  background: #bbb;
  height: 2px;
  width: 60px;
}
.toggle-switch .checkbox label:after {
  background: #bbb;
  top: -16px;
  width: 32px;
  height: 32px;
}
.toggle-switch .checkbox input:checked + label {
  background: #376fcb;
}
.toggle-switch .checkbox input:checked + label:after {
  background: #4285f4;
  left: 40px;
}

/** share */

.share {
  cursor: pointer;
}

.Bottom-Info-Row {
  background-color: #7fff00;
  text-align: center;
  padding: 5px;
}

.Bottom-Info-Row a {
  text-decoration: underline;
}

/** loading screen **/

#loading-screen {
  background-color: black;
  display: flex;
  flex: 1 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
}

#loading-screen ul.animation li {
  display: block;
  float: left;
  font-size: 2em;
  color: #ccc;
  text-shadow: 0 -1px 0 white, 0 1px 0 black;
}
#loading-screen .anim {
  animation: music 0.5s ease-in-out both infinite;
  -webkit-animation: music 0.5s ease-in-out both infinite;
}

@-webkit-keyframes music {
  0%,
  100% {
    -webkit-transform: translate3d(0, -10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 10px, 0);
  }
}

@keyframes music {
  0%,
  100% {
    -webkit-transform: translate3d(0, -10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 10px, 0);
  }
}

